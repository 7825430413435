import { SvarPeriodKompletterandeBidragApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { InputField } from '@local/src/Components'
import React from 'react'
import { Path } from 'react-hook-form'

interface Props {
	field: Path<SvarPeriodKompletterandeBidragApiModel>
	index: number
	label: string
	isReadOnly?: boolean
}

const BidragBeloppInput = ({ field, label, index, isReadOnly = false }: Props) => (
	<InputField
		wrapperSx={{
			flex: 1,
			mb: 1,
			input: {
				background: 'white',
			},
		}}
		name={`perioder.${index}.${field}`}
		label={label}
		inputType={'number'}
		disabled={isReadOnly}
	/>
)

export default BidragBeloppInput
