import { StarksFramtidaStallning } from '@local/Services/SwaggerApi/data-contracts'
import { ansokanValidationText, requiredRadioButtonValidationText } from '@local/src/Utils/YupHelper'
import { maxLengthValidationText, requiredValidationText } from '@local/Utils/YupHelper'
import { SchemaOf, array as yupArray, boolean as yupBoolean, mixed as yupMixed, object as yupObject, string as yupString } from 'yup'

import { OrsakMotiveringSchema, OrsakStarksFramtidaStallningSchema, PersonnummerSchema } from '../../Common/Schemas'
import { orsakMotiveringRequired } from '../../Common/Schemas/OrsakMotiveringSchema'
import { CreateCsnYttrandeFormModel } from '../Models/AdHocYttrande.types'

import UtbildningSchema from './UtbildningSchema'

export const AdHocYttrandeSchema: SchemaOf<CreateCsnYttrandeFormModel> = yupObject({
	isReadOnly: yupBoolean(),
	shouldSendToCsnFormBool: yupBoolean(),
	ansokanId: yupString().trim().required(ansokanValidationText),
	malMedStudier: yupString().trim().required(requiredValidationText).max(50, maxLengthValidationText),
	personnummer: PersonnummerSchema,
	kompletterandeBidrag: yupBoolean().nullable().required(requiredValidationText),
	datumAnsokanOo: yupString().nullable().required(requiredValidationText),
	starksFramtidaStallning: yupMixed().oneOf<StarksFramtidaStallning>([1, 2, 3]).required(requiredValidationText),
	orsakStarksFramtidaStallning: OrsakStarksFramtidaStallningSchema,
	orsakMotivering: yupString().nullable().when('orsakStarksFramtidaStallning', {
		is: orsakMotiveringRequired,
		then: OrsakMotiveringSchema,
		otherwise: yupString().undefinedOrNullSchema(),
	}),
	utbildningar: yupArray().required(requiredValidationText).min(1, 'Du måste lägga till minst 1 utbildning').of(UtbildningSchema),
	isSvarForBegaran: yupBoolean().nullable().required(requiredRadioButtonValidationText),
	begaranId: yupString().when('isSvarForBegaran', {
		is: true,
		then: (schema) => schema.required('Du måste välja begäran om yttrande').nullable(),
		otherwise: yupString().undefinedOrNullSchema(),
	}),
})
