import { BegaranYttrandeStatus, SearchYttrandenStatus } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	status: BegaranYttrandeStatus | SearchYttrandenStatus
}

export const YttrandeStatusChip = ({ status }: Props) => {
	const variant =
		status === BegaranYttrandeStatus.BesvaradWithAdhocYttrande || status === SearchYttrandenStatus.SkickadTillCsn ? 'filled' : 'outlined'

	const { t } = useTranslation('translation', { keyPrefix: 'common.status' })

	const getStatusText = () => {
		switch (status) {
			case BegaranYttrandeStatus.Ny:
			case SearchYttrandenStatus.Ny:
				return t('csnFormState_new')
			case BegaranYttrandeStatus.UnderBedomning:
			case SearchYttrandenStatus.UnderBedomning:
				return t('csnFormState_edit')
			case BegaranYttrandeStatus.Skickad:
			case SearchYttrandenStatus.SkickadTillCsn:
				return t('csnFormState_sent')
			case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
			case SearchYttrandenStatus.BesvaradWithAdhocYttrande:
				return t('csnFormState_adhoc')
			default:
				return t('csnFormState')
		}
	}

	const getTestId = () => {
		switch (status) {
			case BegaranYttrandeStatus.Ny:
			case SearchYttrandenStatus.Ny:
				return 'new'
			case BegaranYttrandeStatus.UnderBedomning:
			case SearchYttrandenStatus.UnderBedomning:
				return 'saved'
			case BegaranYttrandeStatus.Skickad:
			case SearchYttrandenStatus.SkickadTillCsn:
				return 'sent'
			case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
			case SearchYttrandenStatus.BesvaradWithAdhocYttrande:
				return 'adhoc'
			default:
				return 'unknown'
		}
	}

	return (
		<Box display={'flex'} mb={2}>
			<Chip variant={variant} color="primary" label={getStatusText()} data-testid={`yttrande-status-chip-${getTestId()}`} />
		</Box>
	)
}
