import { array as yupArray } from 'yup'
import { MessageParams } from 'yup/lib/types'

export const ShouldBeUndefinedNullOrEmptyArraySchema = yupArray()
	.nullable()
	.default(undefined)
	.test((value) => value === undefined || value === null || value.length === 0)

type MaxLengthParams = MessageParams & {
	max: number
}

type MinLengthParams = MessageParams & {
	min: number
}

type SpecificLengthParams = MessageParams & {
	length: number
}

const isStringType = (value: unknown) => typeof value === 'string' || Object.prototype.toString.call(value) === '[object String]'

export const maxLengthValidationText = (lengthParam: MaxLengthParams) =>
	`Max ${isStringType(lengthParam.value) ? 'antal tecken' : 'värde'} är ${lengthParam.max}`

export const minLengthValidationText = (lengthParam: MinLengthParams) =>
	`Minsta ${isStringType(lengthParam.value) ? 'antal tecken' : 'värde'} är ${lengthParam.min}`

export const specificLengthValidationText = (lengthParam: SpecificLengthParams) => `Du måste ange ${lengthParam.length} antal tecken`

export const emailValidationText = 'Inte giltig email'
export const requiredValidationText = 'Du måste ange ett värde'
export const ansokanValidationText = 'Du måste ange giltig ansokan id'
export const onlyNumbersValidationText = 'Får endast innehålla siffror'
export const phoneNumberValidationText = 'Du måste ange ett giltigt telefonnummer'
export const requiredRadioButtonValidationText = 'Du måste välja ett alternativ'

export const requiredWithLabelValidationText = (messageParams: MessageParams) => `Du behöver ange ${messageParams.label}`
