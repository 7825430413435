import { Box, Card, CardContent, Chip, List, ListItem, ListItemText, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'

const YttrandeCardSkeleton = () => (
	<Card>
		<CardContent>
			<Stack direction={'row'} justifyContent="space-between" mb={2}>
				<Stack>
					<Skeleton>
						<Typography variant="h5">xxxxxxxxxxxxx</Typography>
					</Skeleton>
					<Skeleton>
						<Typography variant="subtitle2">xxxxxxxxxxxxxxxxxxxxx</Typography>
					</Skeleton>
				</Stack>
				<Skeleton>
					<Chip label={`Status`} color="primary" />
				</Skeleton>
			</Stack>

			<Stack direction={'row'} justifyContent="space-between" mb={4}>
				<Box>
					<Skeleton>
						<Typography variant="subtitle2">xxxxxxxxxxxxx</Typography>
					</Skeleton>
					<Skeleton>
						<Typography variant="subtitle1">xxxxxxx</Typography>
					</Skeleton>
				</Box>
				<Box>
					<Skeleton>
						<Typography variant="subtitle2">xxxxxxxxxxxxx</Typography>
					</Skeleton>
					<Skeleton>
						<Typography variant="subtitle1">xxxxxxx</Typography>
					</Skeleton>
				</Box>
				<Box>
					<Skeleton>
						<Typography variant="subtitle2">xxxxxxxxxxxxx</Typography>
					</Skeleton>
					<Skeleton>
						<Typography variant="subtitle1">xxxxxxx</Typography>
					</Skeleton>
				</Box>
			</Stack>

			<Skeleton>
				<Typography variant="h6">xxxxxxxxxxxxx (x xx)</Typography>
			</Skeleton>

			<List>
				<ListItem divider={false} disablePadding>
					<ListItemText
						primary={
							<Skeleton>
								<Typography component="span">xxxxxxxxxxxxxxxxxxxxx</Typography>
							</Skeleton>
						}
						secondary={
							<Skeleton>
								<Typography component="span">xxxxxxxxxx</Typography>
							</Skeleton>
						}
					/>
				</ListItem>
			</List>
		</CardContent>
	</Card>
)

export default YttrandeCardSkeleton
