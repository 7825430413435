import { KompletterandeBidragWithUserProfileMetaApiModel, SapResponseType } from '@local/src/Services/SwaggerApi/data-contracts'
import { Chip, Stack } from '@mui/material'
import { isNotNil } from 'ramda'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	kompletterandeBidrag: KompletterandeBidragWithUserProfileMetaApiModel
}
const KompletterandeBidragStatusChip = ({ kompletterandeBidrag }: Props) => {
	const { shouldSendToCsn } = kompletterandeBidrag
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.kompletterandeBidrag.statusChip' })
	const statusText = shouldSendToCsn ? t('skickadTillCsn') : t('ny')
	const testId = shouldSendToCsn ? 'sent' : 'new'
	const latestAttempt = kompletterandeBidrag.latestSapAttempt
	const isFetchingFromFenix = isNotNil(latestAttempt) && !shouldSendToCsn
	const sapErrorReason = latestAttempt?.responseType === SapResponseType.FailedToFindCsnLon ? t('lonSaknas') : t('manuellHantering')

	return (
		<Stack mb={2} flexDirection="row" gap={1} data-testid={`kompletterande-bidrag-status-chip-${testId}`}>
			{isFetchingFromFenix ? (
				<Fragment>
					<Chip variant="outlined" label={t('hamtningPagar')} color="primary" />
					{kompletterandeBidrag.isEnrichedBySap ? (
						<Chip variant="filled" label={t('redoAttSkickas')} color="primary" />
					) : (
						<Fragment>
							<Chip variant="outlined" color="error" label={sapErrorReason} />
							{latestAttempt?.shouldTryAgain && <Chip variant="outlined" label={t('nastaForsok')} color="primary" />}
						</Fragment>
					)}
				</Fragment>
			) : (
				<Chip variant="outlined" label={statusText} color="primary" />
			)}
		</Stack>
	)
}

export default KompletterandeBidragStatusChip
