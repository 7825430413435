/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client'

export class FeatureFlag<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags FeatureFlag
	 * @name FeatureFlagsEnabledList
	 * @request GET:/studiestod/feature-flags/enabled
	 * @secure
	 * @response `200` `(string)[]` OK
	 */
	featureFlagsEnabledList = (params: RequestParams = {}) =>
		this.request<string[], any>({
			path: `/studiestod/feature-flags/enabled`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		})
}
