import { Box, SxProps, TextField, Theme } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

type InputType = 'password' | 'number' | 'text'

interface IInputField {
	name: string
	label: string
	inputType?: InputType
	disabled?: boolean
	testId?: string
	wrapperSx?: SxProps<Theme>
}

export const InputField = ({ name, label, inputType, disabled, testId, wrapperSx = {} }: IInputField) => {
	const { register, getFieldState } = useFormContext()
	const { error } = getFieldState(name)
	const inputRef = React.useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (inputType !== 'number') return
		if (!inputRef.current) return

		const ref = inputRef.current
		const wheelHandler = (e: WheelEvent) => e.preventDefault()

		ref.addEventListener('wheel', wheelHandler, { passive: false })

		return () => {
			if (ref) ref.removeEventListener('wheel', wheelHandler)
		}
	}, [inputType])

	return (
		<Box sx={wrapperSx}>
			<TextField
				{...register(name, {
					setValueAs: (val: string) => {
						if (inputType !== 'number') return val
						const intVal = parseFloat(val)
						return isNaN(intVal) ? undefined : intVal
					},
				})}
				inputProps={{
					'data-testid': testId || name,
				}}
				type={inputType}
				label={label}
				disabled={disabled}
				error={Boolean(error?.message)}
				helperText={error?.message}
				fullWidth
			/>
		</Box>
	)
}
